<template>
    <div>
      <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :color="'#aa945b'"
        :is-full-page="fullPage">
        </loading>
        <CRow>
            <CCol sm="12" md="12">
                <CCard>
                    <CCardHeader>
                      <b>Search Questions</b>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                          <CCol sm="4" v-for="(value, index) in filters" :key="index" class="mt-2">
                                <label><b>{{value.name}}</b></label>
                                  <multiselect
                                      tag-placeholder="Add this as new tag"
                                      placeholder="Search or add a tag"
                                      label="name"
                                      track-by="id"
                                      :options="value.items"
                                      text-field="name"
                                      :multiple="true"
                                      v-model="value.selected_filter"
                                      :taggable="true"
                                      @input="onSelect"
                                    ></multiselect>
                          </CCol>
                        </CRow>
                        <CRow>
                            <!--<CCol sm="4" class="mt-2">
                                <label><b>Course</b></label>
                                <multiselect
                                    v-model="filter.course"
                                    tag-placeholder="Add this as new tag"
                                    placeholder="Search a tag"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    label="name"
                                    track-by="id"
                                    :options="getCouse()"
                                    :multiple="true"
                                    :taggable="false"
                                    @input="onChange"
                                ></multiselect>
                            </CCol>-->
                        </CRow>
                   
                    </CCardBody>
                    <CCardFooter>
                      <button type="button" class="btn btn-success mr-3" @click="resetFilters">Reset</button>
                    </CCardFooter>
                </CCard>
                <CCard>
                   <CCardHeader>
                        <CRow>
                          <CCol sm="4">
                              <h5>Total Questions <span>({{total}})</span></h5>
                          </CCol>
						  <CCol sm="4">
                              <h5 class="float-right">Tagged <span style="color:green">({{total_tagged}})</span></h5>
                          </CCol>
                          <CCol sm="4">
                              <h5 class="float-right">Untagged <span style="color:red">({{total_untagged}})</span></h5>
                          </CCol>
                        </CRow>

                    </CCardHeader>
                    <CCardBody>
                    <CRow>
                      <CCol sm="3">
                          <CButton class="bulk-tag-button"
                           @click="bulkTagging" :disabled="!selected || !selected.length > 0">
                              Bulk Tagging
                          </CButton>
                      </CCol>
                      <CCol sm="9">
                        <CPagination
                          @update:activePage="linkGen"
                          :activePage.sync="activePage"
                          :pages="pageLength"
                          align="end"
                        />
                      </CCol>
                    </CRow>
                        <CRow>
                          <CCol sm="12">
                            <CDataTable
                                :items="questions"
                                :fields="fields"
                                :noItemsView='{ noResults: "No Questions Found", noItems: "No Questions Found" }'
                                :tableFilter='{ label : "Filter", placeholder:"Type to Search..." }'
                                hover
                                sorter
                                >
                               <template #select-header="{item}">
                                    <input type="checkbox" id="checkbox" v-model="allSelected" @change="toggleAll(allSelected)">
                                </template>
                                <template #select="{item}">
                                    <td>
                                      <input type="checkbox" :id="item.id" :value="item.id" v-model="selected" >
                                    </td>
                                </template>
                                <template #index="{item}">
                                    <td>
                                      {{item.id}}
                                    </td>
                                </template>
                                  <template #question_text="{item, index}">
                                    <td>
                                      <!--Display Answer options for all the question type-->
                                      <multipleBlanks  v-if="item.question_type == 'fill_in_multiple_blanks_question'" :question="item"></multipleBlanks>
                                      <shortAnswer  v-else-if="item.question_type == 'short_answer_question'" :question="item"></shortAnswer>
                                      <matching  v-else-if="item.question_type == 'matching_question'" :question="item"></matching>
                                      <trueFalse  v-else-if="item.question_type == 'true_false_question'" :question="item"></trueFalse>
                                      <multipleAnswers  v-else-if="item.question_type == 'multiple_answers_question'" :question="item"></multipleAnswers>
                                      <multipleChoice  v-else-if="item.question_type == 'multiple_choice_question'" :question="item"></multipleChoice>
                                      <multipleDropDowns  v-else-if="item.question_type == 'multiple_dropdowns_question'" :question="item"></multipleDropDowns>
                                      <numerical  v-else-if="item.question_type == 'numerical_question'" :question="item"></numerical>
                                      <div v-else>{{item.question_type}}</div>
                                      <div style="margin-top:10px" v-if="item.correct_comments!=='' || item.incorrect_comments!=='' || item.neutral_comments!==''">
                                            <div style="color:green; font-weight:bold">Comments:</div>
                                            <span class="mr-2" v-if="item.correct_comments">
                                                <CButton
                                                  color='success'
                                                  v-c-popover="{
                                                    header: 'Correct Comments',
                                                    content: item.correct_comments,
                                                    placement: 'bottom'
                                                  }"
                                                >
                                                Correct
                                                </CButton>
                                            </span>
                                            <span class="mr-2" v-if="item.incorrect_comments">
                                                <CButton
                                                  color="danger"
                                                  v-c-popover="{
                                                    header: 'Incorrect Comments',
                                                    content: item.incorrect_comments,
                                                    placement: 'bottom'
                                                  }"
                                                >
                                                Incorrect
                                                </CButton>
                                            </span>
                                            <span class="mr-2" v-if="item.neutral_comments">
                                                <CButton
                                                  color="primary"
                                                  v-c-popover="{
                                                    header: 'Neutral Comments',
                                                    content: item.neutral_comments,
                                                    placement: 'bottom'
                                                  }"
                                                >
                                                Neutral
                                                </CButton>
                                            </span>
                                      </div>
                                      <div style="margin-top:10px" v-if="item.tags.length">
                                        <div style="color:green; font-weight:bold">Tags:</div>
                                        <span v-for="(tag, t_index) in item.tags" :key="t_index" class="mr-2">
                                          <CBadge color="success" style="font-size:14px; cursor:pointer;margin-top:10px;">{{tag}}</CBadge>
                                        </span>
                                        <span v-if="item.math_cloner_id !== null" >
                                          <CBadge color="info" style="font-size:14px;margin-top:10px;">Math Cloner Question</CBadge>
                                        </span>
                                        <span v-if="item.math_cloner_source_question !== false" >
                                          <CBadge color="info" style="font-size:14px;margin:10px;">Math Cloner Source Question</CBadge>
                                        </span>
                                      </div>
                                    </td>
                                  </template>
                                  <template #vetted="{item}">
                                    <td align="right">                                    
                                      <!-- <a class="ml-4" @click="updateVetStatus(item)">
                                        <CBadge :color="item.vetted==true ? 'success': 'danger'" style="font-size:14px; cursor:pointer;margin-top:10px;">{{item.vetted?"Vetted":"Not Vetted"}}</CBadge>
                                      </a> -->
                                      <CSwitch
                                        class="mx-1"
                                        color='success'
                                        :checked="item.vetted"
                                        @update:checked="(val) => updateVetStatus(val,item)"
                                        shape="pill" 
                                        v-bind="{
                                          labelOn: '\u2713',
                                          labelOff: '\u2715'
                                        }"
                                      />
                                    </td>
                                  </template>
                                  <template  #show_details="{item, index}">
                                    <td align="right">                                    
                                      <a href="javascript:void(0)" class="ml-4" @click="tagQuestion(item)">
                                        <span style="font-size:18px;text-decoration:underline">Edit</span>
                                      </a>
                                      <a target="_blank" :href="item.quiz_url" class="ml-4">
                                        <span style="color:green; font-size:18px;text-decoration:underline">View</span>
                                      </a>
                                      <a href="javascript:void(0)" class="ml-4" @click="syncQuestion(item)">
                                        <span style="font-size:18px;text-decoration:underline">Sync</span>
                                      </a>
                                    </td>
                                  </template>
                              </CDataTable>
                          </CCol>
                      </CRow>

                      <CRow>
                        <CCol sm="3">
                            <CButton class="bulk-tag-button"
                             @click="bulkTagging" :disabled="!selected || !selected.length > 0">
                                Bulk Tagging
                            </CButton>
                        </CCol>
                        <CCol sm="9">
                          <CPagination
                            @update:activePage="linkGen"
                            :activePage.sync="activePage"
                            :pages="pageLength"
                            align="end"
                          />
                        </CCol>
                      </CRow>
                    </CCardBody>
  
                </CCard>
            </CCol>
        </CRow>
        
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.bulk-tag-button{
  background-color:#428bca;
  color:white
}

</style>
<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapState } from "vuex";
import axios from 'axios';


// Components to display different Question Type
import multipleChoice from './multipleChoice';
import multipleBlanks from './multipleBlanks';
import multipleAnswers from './multipleAnswers';
import matching from './matching';
import shortAnswer from './shortAnswer';
import multipleDropDowns from './multipleDropDowns';
import trueFalse from './trueFalse';
import numerical from './numerical';


export default {
    name:'Questions',
    components: {
        Loading,
        multipleChoice,
        multipleBlanks,
        multipleAnswers,
        matching,
        shortAnswer,
        multipleDropDowns,
        trueFalse,
        numerical
    },
    data(){
        return{
            isLoading:false,
            fullPage: true,
            showQuestions:false,
            activePage: 1,
//            perPage:30,
            title:"Questions",
            filter: {},
            selected: [],
            allSelected: false,
            fields:[
                  { key: 'select', _style:'min-width:20px;', label:'', name:'select'},
                  { key: 'index', _style:'min-width:20px;', label:'', label:'SNo'},
                  { key: 'question_text', _style:'min-width:400px;', label:'Question Content' },
                  { key: 'vetted', _style:'width:20px;', label:'', label:'Vetted'},
                  { 
                    key: 'show_details', 
                    label: 'Actions', 
                    _style: 'width:20%', 
                    sorter: false, 
                    filter: false
                  }
                ]
        }
    },
    watch: {
    $route: {
      immediate: true,
      async handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
          await this.fetchQuestions();
        }
      }
    },
    selected(newVal, oldVal) {
      // Handle changes in individual flavour checkboxes
//      console.log(newVal)
      if (newVal.length === 0) {
        this.allSelected = false;
      } else if (newVal.length === this.questions.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
    // async $route(to, from) {
    //   this.$session.set("filter", this.filter);
    //   await this.fetchQuestions();
    // },
  },
    computed: {
    ...mapState({
      questions: state => state.question.questions,
      filters: state => state.question.filters,
      total_tagged: state => state.question.total_tagged,
	  total_untagged: state => state.question.total_untagged,
      total: state => state.question.total,
      perPage: state => state.question.per_page,
    }),
    ...mapState(["tag"]),
    ...mapState(["course"]),
    ...mapState({
      settingLength: state => state.course.setting.length
    }),
    pageLength(){
      let length = 0;

      if(this.total <= this.perPage){
        length = 1
      } else {
          let num = this.total / this.perPage  
          length = Math.ceil(num)
      }



      return length;
    }
  },
   async mounted(){
    this.isLoading = true
    await this.$store.dispatch("auth/fetchUser");
    await this.fetchQuestions();
   },
    methods:{
      async updateVetStatus(val,item){
        this.isLoading = true;
        await this.$store.dispatch("question/updateVetStatus", {
            id: item.id,
            vetted: val
        })
        .then(() => {
            this.isLoading = false;
        })
        .catch(error => {
          console.log("Problem updating vet status", error);
        });
        this.isLoading = false;
      },
      async syncQuestion(item){
        this.isLoading = true;
        await this.$store.dispatch("question/syncQuestion", {
            id: item.id,
        })
        .then(() => {
            this.isLoading = false;
        })
        .catch(error => {
          console.log("Problem syncing question", error);
        });
        this.isLoading = false;
      },
      async resetFilters(){
        this.isLoading = true;
        //console.log(this.filters)
        var obj = {}; 

        for(let i = 0; i < this.filters.length; i++){
            obj[this.filters[i].name] = []; 
        }
            //var stringFilter = encodeURIComponent(JSON.stringify(this.getFilters()));
        await this.$store.dispatch("question/fetch", {
                    query: this.$route.query,
                    filter: obj
                });

        this.isLoading = false;
      },
      getFilters(){
        var obj = {}; 

        for(let i = 0; i < this.filters.length; i++){
            obj[this.filters[i].name] = this.filters[i].selected_filter; 
        }
        
        return obj;
      },
      async searchQuestions(){
        await this.fetchQuestions()
      },
      toggleAll(allSelected) {
        this.selected = allSelected ? this.questions.map(value => value.id) : [];
      },
      linkGen(pageNum) {
        //alert(pageNum)
        if (this.$route.path === "/questions") {
          // return {
          //   path: "questions?page=" + pageNum
          // };
            this.$router.push({ query: { page: pageNum }})
        } 
        else 
        {
          this.$router.push({ query: { page: pageNum }})
        }
      },
      async tagQuestion(question){
//        console.log(question)
        this.selected = []
        this.selected.push(question.id)
        await this.bulkTagging()
      },
      async bulkTagging() {
        // var selected = this.selected.join(", ")
        var selected = this.selected
        await this.$store
          .dispatch("question/fetchSelectedQuestions", {
            selected: this.selected
          })
          .then(() =>
            this.$router.push("/questions/bulk-tagging?query_string=" + selected)
          );
      },
    async onSelect(selectedOption, id){
       await this.searchQuestions()
    },
    async onDeselect(selectedOption, id) {
//      console.log(selectedOption)
//      console.log(id)
    },
    async onChange() {
                var totalLength = 0;
                for (var prop in this.filter.tag) {
                    if (
                    this.filter.tag.hasOwnProperty(prop) &&
                    this.filter.tag[prop] != null
                    ) {
                    totalLength += this.filter.tag[prop].length;
                    }
                }
            if (totalLength == 0) {
                this.filter.tag = [];
            }
            //this.$session.set("filter", this.filter);
            await this.fetchQuestions();
        },
        getCouse() {
            if (this.settingLength > 0) {
                return this.course.setting;
            } else {
                return this.course.data;
            }
        },
        async fetchQuestions() {
            this.isLoading = true;
            //var stringFilter = encodeURIComponent(JSON.stringify(this.getFilters()));
            await this.$store.dispatch("question/fetch", {
                    query: this.$route.query,
                    filter: this.getFilters()
                });

            this.isLoading = false;
        },
        pageChange (val) {
            this.$router.push({ query: { page: val }})
        },
        searchWS()
        {
            this.showQuestions = true
        }
    }
}
</script>